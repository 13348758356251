@import './variables';

.mat-raised-button,
.mat-stroked-button {
  box-shadow: none!important;
}

/* Loading */
.btn-loading {
  font-size: 0;
  .mat-button-wrapper {
    opacity: 0;
  }
  &:after {
    content: '';
    display: block;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    font-size: 3px;
    border-top: 1.1em solid #FFFFFF;
    border-right: 1.1em solid #FFFFFF;
    border-bottom: 1.1em solid #FFFFFF;
    border-left: 1.1em solid $primary-color;
    position: absolute;
    left: calc(50% - 12px);
    top: calc(50% - 12px);
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: loading-spinner 1.1s infinite linear;
    animation: loading-spinner 1.1s infinite linear;
  }
}

body {
  .mat-button-base {
    padding: 7px 0;
    line-height: 24px;

    .icon {
      margin-right: 8px;
    }
    //.mat-button-ripple.mat-ripple,
    //.mat-button-focus-overlay {
    //  display: none;
    //}
  }

  .mat-button-focus-overlay {
    background: rgba(5, 27, 82);
  }
  .mat-button:focus:not(.mat-button-disabled) .mat-button-focus-overlay,
  .mat-stroked-button:focus:not(.mat-button-disabled) .mat-button-focus-overlay {
    opacity: .12;
  }
  .mat-button,
  .mat-stroked-button,
  .mat-flat-button,
  .mat-raised-button {
    padding: 8px 20px;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    border-radius: $border-radius;
    white-space: initial;

    &.short-btn {
      padding-left: 12px;
      padding-right: 12px;
    }
    &.btn-icon {
      line-height: 1;

      .mat-button-wrapper {
        display: inline-flex;
        align-items: center;
        justify-content: center;
      }
      &:not(.w-100) {
        padding-left: 12px;
      }
    }
    .icon {
      font-weight: normal;
      font-size: 24px;
      line-height: 1;
    }
    &.max-btn {
      padding: 0;
      position: absolute;
      right: 0;
      top: -3px;
      z-index: 1;
      min-width: auto;
    }
  }

  .mat-stroked-button {
    padding: 7px 20px;
    line-height: 24px;

    .icon {
      opacity: 0.24;
    }
    &:not(.mat-button-disabled) {
      border-color: rgba(5, 27, 82, 0.16);
    }
  }

  .mat-icon-button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    line-height: 1;
    border: 1px solid rgba(5, 27, 82, 0.16);
    box-sizing: border-box;
    border-radius: $border-radius;
    color: rgba($text-color, .4);
    transition: all 0.3s;

    &:hover {
      background: rgba(5, 27, 82, 0.04);
      color: $text-color;
      .icon-bin {
        opacity: unset !important;
      }
    }

    &.btn-loading {
      &:after {
        border-top-color: rgba($primary-color, 0.3);
        border-right-color: rgba($primary-color, 0.3);
        border-bottom-color: rgba($primary-color, 0.3);
        border-left-color: $primary-color;
      }
    }
  }

  /* Primary */
  .mat-flat-button.mat-primary,
  .mat-raised-button.mat-primary,
  .mat-fab.mat-primary,
  .mat-mini-fab.mat-primary {
    background-color: #31c2b9;
    color: #ffffff;

    &:hover {
      background-color: #74DAD4;
      color: #ffffff;
    }

    &:focus,
    &.active {
      background-color: #289FA6;
      color: #ffffff;
    }

    &.disabled,
    &:disabled {
      color: #ffffff;
      background-color: #33c9c0;
      opacity: 0.32;
    }
  }

  .mat-button.dropdown-button {
    padding: 8px 50px 8px 16px;
    background: #F8FAFF;
    box-shadow: inset 0px -1px 0px #051B52;
    border-radius: 8px 8px 0 0;
    text-align: left;

    img {
      width: 24px;
      height: 24px;
      object-fit: contain;
      margin-right: 10px;
    }

    &:after {
      content: "\e969";
      font-family: 'icomoon';
      font-size: 24px;
      line-height: 1;
      position: absolute;
      right: 16px;
      top: 8px;
    }
  }
}


.btn {
  position: relative;
  display: inline-block;
  border: none;
  color: $text-color;
  padding: 8px 24px;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  border-radius: $border-radius;
  transition: all 0.3s;
  overflow: hidden;
  text-align: center;

  /* Outline */
  &[class^="btn-outline-"],
  &[class*=" btn-outline-"] {
    padding: 7px 24px;
  }

  &:focus {
    outline: none;
    box-shadow: none;
  }
  &.disabled,
  &:disabled {
    pointer-events: none;
    opacity: 0.32;
  }

  /* With icon */
  &-icon {
    font-weight: normal;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
  &-icon-left .icon {
    margin-right: 8px;
  }
  &-icon-right .icon {
    margin-left: 8px;
  }
  .icon {
    font-size: 24px;
    vertical-align: middle;
    line-height: 22px;
  }
}

/* Primary (Green) */
.btn-primary {
  background-color: #31C2B9;
  border-color: #31C2B9;
  color: #ffffff;

  &:hover {
    background-color: #74DAD4;
    border-color: #74DAD4;
    color: #ffffff;
  }

  &:focus,
  &.active {
    background-color: #289FA6;
    border-color: #289FA6;
    color: #ffffff;
  }

  &.disabled,
  &:disabled {
    color: #ffffff;
    background-color: #33c9c0;
    border-color: #33c9c0;
  }
}

/* Secondary (Grey) */
.btn-secondary {
  background: #f5f6f8;
  border-color: #f5f6f8;
  color: $text-color;

  &:hover {
    background: #f8f9fa;
    border-color: #f8f9fa;
    color: $text-color;
  }

  &:focus,
  &.active,
  &:active {
    background: #ebedf1;
    border-color: #ebedf1;
    color: $text-color;
  }

  &.disabled,
  &:disabled {

  }
}

/* Secondary Outline (Grey) */
.btn-outline-secondary {
  background: none;
  color: rgba($text-color, 0.8);
  border: 1px solid rgba(5, 27, 82, 0.16);

  &:hover {
    background: rgba(5, 27, 82, 0.04);
    border-color: rgba(5, 27, 82, 0.16);
    color: rgba($text-color, 0.8);
  }
  &:focus,
  &.active,
  &:active {
    background: rgba(5, 27, 82, 0.12);
    border-color: rgba(5, 27, 82, 0.16);
    color: rgba($text-color, 0.8);
  }
  .icon {
    opacity: 0.24;
  }
}


.btn-saved,
.btn-saved.disabled,
.btn-saved:disabled,
fieldset:disabled .btn-saved {
  border-color: #def6f5;
  background: #def6f5;
  color: #16757b;
}


.btn-circle {
  border-radius: 100%;
  width: 40px;
  height: 40px;
  line-height: 39px;
  padding: 0;
  font-weight: normal;
  font-size: 24px;
  text-align: center;
}
.btn-circle.btn-secondary {
  color: rgba(0, 0, 0, 0.4);
}
.btn-circle.btn-secondary:hover {
  color: $text-color;
}

.btn-group-justified > .mat-button-base,
.btn-group-justified > .btn {
  width: 100%;
  margin-bottom: 10px;
}
@media (min-width: 768px) {
  .btn-group-justified {
    display: flex;
    align-items: center;
    margin-left: -10px;
    margin-right: -10px;
  }
  .btn-group-justified > .mat-button-base,
  .btn-group-justified > .btn {
    flex: 1 0 0;
    margin: 0 10px 0;
  }
}



@import './variables';

body {
  .mat-tooltip {
    margin: 10px;
    padding: 8px 12px;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.16), rgba(255, 255, 255, 0.16)), #1C253A;
    border-radius: $border-radius;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.25px;
    color: #FFFFFF!important;
  }
}


.info-tooltip {
  font-size: 24px;
  color: $primary-color;
  cursor: pointer;
}
.tooltip {
  filter: drop-shadow(0px 4px 16px rgba(59, 69, 91, 0.12));
}
.tooltip-inner {
  padding: 12px 16px!important;
  min-width: 320px;
  background: #ffffff;
  border-radius: 16px;
  color: $text-muted;
  text-align: left;
  font-weight: normal;
  font-size: 14px;
}
.tooltip.show {
  opacity: 1;
}
.bs-tooltip-auto[data-popper-placement^="left"],
.bs-tooltip-start,
.bs-tooltip-auto[data-popper-placement^="right"],
.bs-tooltip-end {
  padding: 0 12px;
}
.bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow,
.bs-tooltip-start .tooltip-arrow {
  right: 6px;
  margin-top: -3px;
}
.bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow::before,
.bs-tooltip-start .tooltip-arrow::before {
  border-left-color: #ffffff;
  border-width: 9px 0 9px 9px;
}

.bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow,
.bs-tooltip-end .tooltip-arrow {
  margin-top: -4px;
  left: 6px;
}
.bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow::before,
.bs-tooltip-end .tooltip-arrow::before {
  border-right-color: #ffffff;
  border-width: 9px 9px 9px 0;
}

@import './variables';

body {
  font-family: "Mulish", sans-serif;
  font-size: 16px;
  line-height: 1.5;
  font-weight: 500;
  color: #051b52;
}
h1,h2,h3,h4,h5,h6,
.h1,.h2,.h3,.h4,.h5,.h6 {
  font-weight: normal;
}
.h1,.h2,.h3,.h4,.h5,.h6 {
  margin-bottom: 0;
}
h1,.h1 {
  font-weight: 300;
  font-size: 96px;
  line-height: 1.17;
  letter-spacing: -1.5px;
}
h2,.h2 {
  font-weight: 300;
  font-size: 60px;
  line-height: 1.2;
  letter-spacing: -0.5px;
}
h3,.h3 {
  font-weight: 500;
  font-size: 48px;
  line-height: 1.17;
}
h4,.h4{
  font-weight: 500;
  font-size: 34px;
  line-height: 1.06;
}
h5,.h5{
  font-weight: 500;
  font-size: 24px;
  line-height: 1;
  letter-spacing: 0.18px;
}
h6,.h6{
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.15px;
}
.caption {
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.4px;
  font-weight: 500;
}
.overline {
  font-weight: 600;
  font-size: 10px;
  line-height: 16px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
}
.subtitle-1 {
  font-weight: 500;
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: 0.15px;
}
.subtitle-2 {
  font-weight: 600;
  font-size: 14px;
  line-height: 1.71;
  letter-spacing: 0.1px;
}
.small-text {
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
}
.fw-600 {
  font-weight: 600;
}
.text-primary {
  color: $primary-color!important;
}
.text-success {
  color: $success!important;
}
.text-muted {
  color: $text-muted!important;
}
.mute-80 {
  color: rgba($text-color, 0.8);
}
.text-muted h1,
.text-muted h2,
.text-muted h3 {
  color: $text-color;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
a {
  text-decoration: none;
  color: $primary-color;
}
a:hover {
  color: $primary-color;
}
.text-break {
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-all;
  word-break: break-word;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}

.label {
  font-weight: 600;
  font-size: 16px;
}
.small-label {
  font-size: 14px;
}
.value {
  font-weight: 600;
  font-size: 24px;
  line-height: 1.24;
}
.value-lg {
  font-size: 32px;
}
.convert-value {
  font-size: 16px;
  display: block;
}

.icon {
  font-size: 24px;
  line-height: 1;

  &-sm {
    font-size: 16px;
  }
  &-lg {
    font-size: 49px;
  }
}

.image-24 {
  width: 24px;
  height: 24px;
  object-fit: cover;
  border-radius: 100%;
}

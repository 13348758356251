@import './variables';


.progress {
  display: flex;
  overflow: hidden;
  background: rgba($primary-color, .24);
  height: 13px;
  border-radius: $border-radius;
  position: relative;

  &-bar {
    display: flex;
    overflow: hidden;
    flex-direction: column;
    justify-content: center;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    transition: width .6s ease;
    background-color: $primary-color;
    border-radius: $border-radius;
  }
  &-xs {
    height: 4px;
  }
  &-sm {
    height: 8px;
  }
  &-lg {
    height: 48px;

    .progress-bar {
      border-radius: $border-radius 0 0 $border-radius;
    }
  }

  &-legend {
    margin-top: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: normal;
    font-size: 14px;
    line-height: 14px;

    &__percent {

    }
    &__value {
      opacity: 0.64;
      margin-left: auto;
    }
    &__img {
      width: 20px;
      height: 20px;
      border-radius: 100%;
      overflow: hidden;
      object-fit: cover;
      margin-left: 8px;
    }
  }

  &-label {
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 1;
    padding: 4px 20px;
    font-size: 14px;
    line-height: 20px;
    color: $text-color;
    background: #FFFFFF;
    border-radius: $border-radius;
    box-shadow: 0 4px 8px rgba(59, 69, 91, 0.04);
  }
}

.progress-status {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
  margin-top: 4px;
}

.progress-orange.progress,
.status-progress.progress,
.status-upcoming.progress {
  background: #ffeec2;
}
.progress-orange .progress-bar,
.status-upcoming .progress-bar,
.status-progress .progress-bar {
  background-color: #ffb800;
}

.progress-group .progress {
  width: 100%;
  margin: 15px 0 10px;
}

@import './variables';

.deal-card {
  &__info {
    padding-top: 11px;
  }
  .deal-name {
    margin: 4px 0 8px;
  }

  .deal-progress {
    margin-top: 20px;

    @media (min-width: $breakpoint-lg) {
      margin-top: 0;
    }
  }
}

.token-name {
  display: flex;
  align-items: center;

  &__img {
    width: 40px;
    flex: 0 0 40px;

    img {
      width: 40px;
      height: 40px;
      object-fit: cover;
      border-radius: 100%;
    }
  }
  &__info {
    width: calc(100% - 40px);
    flex: 0 0 calc(100% - 40px);
    padding-left: 12px;
  }

  &-sm {
    .token-name__img {
      width: 24px;
      flex: 0 0 24px;

      img {
        width: 24px;
        height: 24px;
      }
    }
    .token-name__info {
      width: calc(100% - 24px);
      flex: 0 0 calc(100% - 24px);
    }
  }

  .token-title {
    letter-spacing: 0.15px;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
  }

  .token-symbol {
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.1px;
    color: $text-muted;

    @media (min-width: $breakpoint-lg) {
      font-weight: 600;
    }
  }

  &-inline {
    .token-title {
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
      margin: 0;
    }
    .token-symbol {
      font-size: 16px;
      line-height: 24px;
      margin: 4px 0 0;
    }
    @media (min-width: $breakpoint-lg) {
      .token-name__info {
        display: flex;
        align-items: center;
      }
      .token-symbol {
        margin: 0 0 0 6px;
        font-size: 20px;
      }
    }
  }
}

.network-name {
  display: flex;
  align-items: center;

  &__title {
    font-size: 16px;
    line-height: 1;
    letter-spacing: 0.15px;
  }
  &__img {
    width: 24px;
    height: 24px;
    object-fit: contain;
    margin-right: 8px;
    vertical-align: initial;
  }
}

.deal-countdown {

}

.ref-panel {
  --border-color: rgba(59, 69, 91, 0.08);

  &.mat-expansion-panel {
    box-sizing: border-box;
    border-radius: 0  !important;
    box-shadow: none !important;
    color: inherit;
    border-bottom: 1px solid var(--border-color);

    &.mat-expanded {
      border-bottom: none;

      &.mat-expansion-panel-spacing {
        margin: 0;

        .mat-expansion-panel:first-of-type {
          border-top: 1px solid var(--border-color);
        }
      }

      .mat-expansion-panel {
        &:first-of-type {
          border-bottom: 1px solid var(--border-color);
        }
      }
    }

    .mat-expansion-panel-header {
      font-size: 14px;
      padding: 0 1.9rem 0 1rem;
      height: 88px;
    }

    .mat-expansion-panel-content .mat-expansion-panel-body {
      padding: 0;
      position: relative;

      &::before {
        content: '';
        width: 1px;
        height: calc(100% - 44px);
        background-color: #d7dae3;
        position: absolute;
        top: 0;
        left: 40px;
        z-index: 999;
      }
    }

    .mat-expansion-panel {
      padding-left: 2rem;
      border-bottom: 1px solid var(--border-color);

      &::before {
        content: '';
        width: 13px;
        height: 50px;
        border-bottom: 1px solid #d7dae3;
        border-left: 1px solid #d7dae3;
        border-bottom-left-radius: 8px;
        position: absolute;
        top: 0;
        left: 40px;
        z-index: 999;
      }
    }
  }
}

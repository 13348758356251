@import './variables';

.tiers-table {
  thead {
    th {
      font-size: 16px;

      @media (min-width: $breakpoint-lg) {
        width: calc(50% - 180px);
      }

      &:first-child {
        width: 60px;
      }
      &:nth-child(2) {
        width: 60px;
      }
      &:last-child {
        width: 60px;
      }
    }
  }
  tbody {
    td {
      padding: 11px 12px;
      vertical-align: top;
      line-height: 40px;

      &.edit-opened {
        padding-bottom: 4px;
      }
    }
    tr:last-child {
      .tiers-table__editable {
        padding-top: 4px;
        padding-bottom: 4px;
      }
    }
  }
}

.tier-group {
  img {
    width: 20px;
    height: 20px;
    border-radius: 100%;
    object-fit: cover;
  }

  .tier-group-logo {
    margin-right: 8px;
  }
  .tier-group-chain {
    margin-right: 4px;
  }

  &__title {
    letter-spacing: 0.4px;
  }
  .progress-legend {
    margin-top: 4px;
  }
}
